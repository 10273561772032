import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
 import s3 from '../assets/productos/SCS-S3.png'
 import s7evo from '../assets/productos/SCS-S7_EVO.png'
 import s8new from '../assets/productos/SCS-S8_NEW.png'
 import s8old from '../assets/productos/SCS-S8_OLD.png'
 import s9 from '../assets/productos/SCS-S9.png'
 import s11 from '../assets/productos/SCS-S11.png'
 import S13XModal from './info-items/S13XModal'
 import S11XModal from './info-items/S11XModal'
 import S9XModal from "./info-items/S9XModal";
 import S8XNewModal from "./info-items/S8XNewModal";
 import S8XOldModal from "./info-items/S8XOldModal";
 import S7EvoXModal from "./info-items/S7EvoXModal";


// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";



// import required modules
import { FreeMode, Pagination } from "swiper";
import S3XModal from "./info-items/S3XModal";


export const CarouselMobile = () => {

  const [isShown, setIsShown] = useState(false);

  return (
    <section className='slideMobile'>
     
      <div className='Carousel' data-aos="fade-left" data-aos-duration="1000">
        <Swiper
          slidesPerView={1}
          spaceBetween={1}
          freeMode={true}
          pagination={{
            clickable: true,
          }}
          modules={[FreeMode, Pagination]}
          className="mySwiper"
        >
        <SwiperSlide><div class="glassBox">
            <div class="glassBox__imgBox">
            <S13XModal />
            </div>
          </div>
          </SwiperSlide>
        <SwiperSlide><div class="glassBox">
            <div class="glassBox__imgBox">
            <S11XModal />
            </div>
          </div>
          </SwiperSlide>
          <SwiperSlide><div class="glassBox">
            <div class="glassBox__imgBox">
              <S8XNewModal />
            </div>
          </div>
          </SwiperSlide>
          <SwiperSlide><div class="glassBox">
            <div class="glassBox__imgBox">
              <S8XOldModal />
            </div>
          </div>
          </SwiperSlide>
          <SwiperSlide><div class="glassBox">
            <div class="glassBox__imgBox">
              <S9XModal />
            </div>
          </div></SwiperSlide>
          <SwiperSlide><div class="glassBox">
            <div class="glassBox__imgBox">
              <S7EvoXModal />
            </div>
          </div>
          </SwiperSlide>
          <SwiperSlide><div class="glassBox" >
            <div class="glassBox__imgBox">
              <S3XModal />
            </div>
          </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  )
}

export default CarouselMobile
