import React from 'react'
import s9 from '../../assets/productos/SCS-S9.png'
import S9ScrollX from './content/S9ScrollX'
import xIconL from '../../assets/icons/x-iconL.png'
import S9ScrollXMobile from './content-mobile/S9XMobile'




// import { IconName, AiOutlineClose } from "react-icons/ai";

export const S9XModal = () => {
  return (
    <div>
        
        <input type="checkbox" id="btn-modal2" />
                  <label for="btn-modal2" className="lbl-modal2 pointer"><img src={s9} /></label>
                  <h3 class="glassBox__title">S9</h3>
                  <label for="btn-modal2" className="lbl-modal2 pointer"><h2>Saber más</h2></label>
                   <div className="modal2">
                <div className="contenedor2">
                  <label for="btn-modal2"><img src={xIconL}/></label>
                  <div className='productos-content desktop'><S9ScrollX/></div>
                  <div className='productos-content mobile'><S9ScrollXMobile/></div>
                </div>
              </div>
    </div>
  )
}

export default S9XModal