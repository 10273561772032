import React from 'react'
import s11 from '../../assets/productos/SCS-S11.png'
import S11ScrollX from './content/S11ScrollX'
import S11ScrollXMobile from './content-mobile/S11ScrollXMobile'
import xIconL from '../../assets/icons/x-iconL.png'




// import { IconName, AiOutlineClose } from "react-icons/ai";

export const S11XModal = () => {
  return (
    <div>
        
        <input type="checkbox" id="btn-modal1" />
                  <label for="btn-modal1" className="lbl-modal1 pointer"><img src={s11} /></label>
                  <h3 class="glassBox__title">S11</h3>
                  <label for="btn-modal1" className="lbl-modal1 pointer"><h2>Saber más</h2></label>
                   <div className="modal1">
                <div className="contenedor1">
                  <label for="btn-modal1"><img src={xIconL}/></label>
                  <div className='productos-content desktop'><S11ScrollX/></div>
                  <div className='productos-content mobile'><S11ScrollXMobile/></div> 
                </div>
              </div>
    </div>
  )
}

export default S11XModal