import React from 'react'
import s11 from '../assets/img/s11_responsive.jpg'
import s8 from '../assets/img/s08_responsive.jpg'
import s7 from '../assets/img/s07_responsive.jpg'
import Carousel from 'react-bootstrap/Carousel'
import s13b from '../../src/assets/img/s13blackm.png'
import s13g from '../../src/assets/img/s13bg.png'
import s13bl from '../../src/assets/img/s13bluem.png'


export const DestacadosMobile = () => {
    return (
        <section className='destacadosMobile'>
           <div className='destacadosMobileContent'>

                <div className='S13Mobile' data-aos="fade-up" data-aos-duration="1000">
                <label for="btn-modal7" className="lbl-modal7 pointer">
                    <div className='div-s13'>
                        <div className='slide-s13'>
                        <Carousel fade indicators={false} controls={false} interval={1000} pause={false}>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={s13b}
                                    alt="First slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={s13g}
                                    alt="Second slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={s13bl}
                                    alt="Second slide"
                                />
                            </Carousel.Item>
                        </Carousel>
                        </div>
                    </div>
                    </label>

                </div>

            
            

                {/* S11 SECCION */}
                <div className='mobileTamaño'>
                <label for="btn-modal1" className="lbl-modal1 pointer">
                    <div className='S11Mobile' data-aos="fade-up" data-aos-duration="1000">
                        <img src={s11} alt="S11 Intercomunicador SCS" />
                    </div>
                    </label>
                </div>
                {/* S8 NEW SECCIÓN  */}
                <div className='mobileTamaño'>
                <label for="btn-modal3" className="lbl-modal3 pointer">
                    <div className='S8Mobile' data-aos="fade-up" data-aos-duration="1000">
                        <img src={s8} alt="S8 Intercomunicador SCS" />
                    </div>
                </label>
                </div>
                {/* S7 EVO SECCION  */}
                <div className='mobileTamaño'>
                <label for="btn-modal5" className="lbl-modal5 pointer">
                    <div className='S7Mobile' data-aos="fade-up" data-aos-duration="1000">
                        <img src={s7} alt="S7 Intercomunicador SCS" />
                    </div>
                    </label>
                </div>

            </div>
        </section>
    )
}


export default DestacadosMobile