import React from "react";
import s7Evo from '../../../assets/productos/SCS-S7_EVO.png'
import lifeStyle from '../../../assets/LifeStyle/SCS-S7E.png'
import features from '../../../../src/assets/productos/S7-Features-Desktop.png'

export const S7EvoScrollX = () => {
  return (
    <div className='outer-wrapper'>

      {/* SECCION 1  */}

      <div className='slide one direction'>
        <div className='features-container'>

          <div className='title-container'>
            <div className="div-title">
              <div className='title-featuress8'>
                <h2>S7 EVO</h2>
              </div>
              <div className='scs-features'>
                <img src={s7Evo} alt='Intecomunicador S7Evo SCS' />
              </div>
            </div>
            <div className='features-img s13i'>
              <img src={features} alt="S7 intercom"/> 
          </div>
          </div>
          
        </div>
      </div>

      {/* SECCION 2  */}

      <div className='slide two direction'>
        <div className='features-container'>
          <div className='descripcion-text'>
            <p className='d-text'>
              Escucha música de forma inalámbrica con tecnología
              Bluetooth integrada. Puede realizar o recibir llamadas
              a través de Bluetooth. Batería de iones de litio.
            </p>
          </div>
        </div>
      </div>

      {/* SECCION 3  */}

      <div className='slide two direction'>
        <div className='features-container'>
          <div className='life-title'>
            <div className="div-space">
              <h2>CONECTADOS<br/>DURANTE LA<br/>AVENTURA</h2>
            </div>
            <div className='life-img-features'>
              <img src={lifeStyle} alt='S7 Life Style Intercom'/>
            </div>
          </div>

        </div>
      </div>

    </div>


  )
}

export default S7EvoScrollX