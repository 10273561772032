import React from 'react'
import NovedadImg from '../assets/img/s13_div_background.png'
import NovedadImgMobile from '../assets/img/s8xmobile.png'
import Coming from '../assets/img/coming.png'

export const Novedades = () => {
  return (
    <section id='novedades'>
      {/* VERSIÓN ESCRITORIO  */}
      <div className='novedades_content'>
        <div className='novedades_box' data-aos="fade-up" data-aos-duration="1000">
          <div className='coming-img'>
          <img src={Coming} alt="Intercomunicador SCS"/>
          </div>
          <div className='novedades-img'>
            <img src={NovedadImg} alt="Intercomunicador SCS"/>
          </div>
        </div>
      </div>

      {/* VERSIÓN MÓVIL */}

      <div className='novedades_content_mobile'>
        <div className='novedades_box_mobile' data-aos="fade-up" data-aos-duration="1000">
            <img src={NovedadImgMobile} alt="Intercomunicador SCS"/>
        </div>
      </div>
    </section>
  )
}


export default Novedades