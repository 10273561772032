import React from 'react'
import emailjs from "@emailjs/browser";

export const Contact = () => {

  const sendEmail = (event) =>{
    event.preventDefault();

    emailjs.sendForm('service_werc80e','template_5wevefj', event.target ,'LDjPIptUSKkawPHcW')
    .then(response => console.log(response))
    .catch(error => console.log(error))
  }


  return (
    <section className='contact-section'>
      <div className='contact-container'>
        <div className='contact-content'>
          <form className='form-contact' onSubmit={sendEmail}>
            <input className='input-form' name="user_name" type="text" placeholder="Nombre Completo" />
            <input className='input-form' name="user_phone" type="text" placeholder="Número de contacto" />
            <input className='input-form' name="user_email" type="email" placeholder="Correo electrónico" />
            <textarea className='text-area' name="user_message" placeholder='Escribe tu mensaje'></textarea>
            <button type="submit">Enviar</button>
          </form>

        </div>
      </div>
    </section>
  )
}

export default Contact
