import React from 'react'
import s3 from '../../../assets/productos/SCS-S3.png'
import features from '../../../../src/assets/productos/S3-Featues-Mobile.png'
import Carousel from 'react-bootstrap/Carousel'
import S3_1 from '../../../assets/LifeStyleMobile/S3-1.webp'
import S3_2 from '../../../assets/LifeStyleMobile/S3-2.webp'
import S3_3 from '../../../assets/LifeStyleMobile/S3-3.webp'
import S3_4 from '../../../assets/LifeStyleMobile/S3-4.webp'







export const S3ScrollXMobile = () => {
  return (

    <div className='outer-wrapper mobile'>

      {/* SECCION 1 PART1  */}

      <div className='slide one direction'>
        <div className='features-container'>
          <div className='title-container-mobile'>
            <div className='title-features'>
              <h2>S3</h2>
            </div>
            <div className='scs-features'>
              <img src={s3} />
            </div>
          </div>

        </div>
      </div>

      {/* SECCION 1 PARTE 2  */}

      <div className='slide one direction'>
        <div className='features-container'>
          <div className='features-img'>
            <img src={features} alt=""/>
          </div>
        </div>
      </div>

      {/* SECCION 2  */}

      <div className='slide two direction'>
        <div className='features-container'>
          <div className='descripcion-text'>
            <p className='d-text'>
              Proporciona covertura a los conductores separados
              por 1000 mts. Audio HD y reducción de ruido avanzada,
              Estructura a prueba de lluvia, función de mensajes de
              voz, etc.
            </p>
          </div>
        </div>
      </div>

      {/* SECCION 3 PARTE 1  */}
      <div className='slide two direction'>
        <div className='features-container'>
          <div className='life-title'>
            <h2>CONECTA CON TODOS</h2>
          </div>
        </div>
      </div>

      {/* SECCION 3 PARTE 2  */}

      <div className='slide one direction'>
        <div className='features-container'>
          <div className='life-img-features'>
          <Carousel fade controls={false} interval={2000} indicators={false}>
              <Carousel.Item>
                <img
                  className="d-block"
                  src={S3_1}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block"
                  src={S3_2}
                  alt="Second slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block"
                  src={S3_3}
                  alt="Third slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block"
                  src={S3_4}
                  alt="Third slide"
                />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>

    </div>

  )
}

export default S3ScrollXMobile