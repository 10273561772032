import React from "react";
import { motion } from "framer-motion";
import navLogo from '../assets/img/LogoSCS.png'
import { IconName, FaWhatsapp } from "react-icons/fa";


const Header = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -180 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        ease: "easeInOut",
        duration: 1,
        delay: 0.6,
      }}
      className='header'>
      <div className='header-inner'>
      <a href='/'><div className='logo'><img src={navLogo} className="LogoNav" alt="Logo SCS intercomunicadores"/></div></a>
        <nav className='nav'>
          <li>
            <a href='#products'>Productos</a>
          </li>
          <li>
            <a href='#destacados'>Destacados</a>
          </li>
          <li>
            <a href='#gallery'>Galería</a>
          </li>
          <li>
            <a className="tiendas" href='#distribuidores'>Tiendas</a>
          </li>
          
        </nav>
        <div className='contact'>
        <a  href="https://wa.me/573209305030" target="_blank"><button data-hover="Compra Mayorista"><div>WhatsApp</div></button></a>
        </div>
       
      </div>
    </motion.div>
  );
};

export default Header;
