import React, { useState } from 'react'
import navLogo from '../assets/img/LogoSCS.png'

export const NavbarMobile = () => {

    const [clicked, setClicked] = useState(false) 
  

    return (
        <section className='NavbarMobile'>
            <div className='NavbarMobileContent'>
            <div className='logoNav'>
            <a href="https://front.codes/" class="logo">
                <img src={navLogo} alt="" />
            </a>
            </div>
            <input className="menu-icon" type="checkbox" id="menu-icon" name="menu-icon" />
            <label for="menu-icon" onclick={() => setClicked(!clicked)}></label>
            <nav className="navMobile">
                <ul className="pt-5" clicked={clicked}>
                    <li><a href="#products" data-text="Productos">Productos</a></li>
                    <li><a href="#destacados" data-text="Destacados">Destacados</a></li>
                    <li><a href="#gallery" data-text="Galería">Galería</a></li>
                    <li><a href="#distribuidores" data-text="Aliados">Tienda</a></li>
                    <li><a href="https://wa.me/573209305030" data-text="Contacto">Contactar</a></li>
                   
                </ul>
            </nav>


            </div>
        </section>
    )
}


export default NavbarMobile