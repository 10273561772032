import React from 'react'
import { FaPhone, FaMapMarkerAlt, FaRegEnvelope } from "react-icons/fa";
import Contact from './Contact';

export const Distribuidores = () => {
  return (
    <section id='distribuidores'>
      <div className='distribuidoresContent' data-aos="fade-up" data-aos-duration="1000">
          <div className='infoDistri'>
            <div className='infoTitle'><h2>¿Quieres ser distribuidor oficial?</h2></div>
            <div className='infoContacto'>
              <div className='info'>
              <p><FaPhone /> 7441911 Ext. 117</p>
              <p><FaMapMarkerAlt /> Cl 17 # 14-29 Piso 2</p>
              <p><FaRegEnvelope /> info@distrifabrica.com</p>
              </div>
              <div className='formulario'>
            <Contact />
          </div>
            </div>
            
          
          
        </div>
      </div>
    </section>
  )
}


export default Distribuidores