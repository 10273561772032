import React from 'react';
import s3 from '../assets/gallery/S3.webp'
import s8new from '../assets/gallery/S8_NEW.webp'
import s8old from '../assets/gallery/S8_OLD.webp'
import s11 from '../assets/gallery/S11.webp'
import Footer from '../components/Footer'

export const Gallery = () => {
  return (
    <section className='Gallery'  >
      <div class="containerGallery" data-aos="fade-up" data-aos-duration="1000">
  <div class="card" id='gallery'>
    <img src={s11}/>
    <div class="card__head">SCS 11</div>
  </div>
  <div class="card">
    <img src={s8new}/>
    <div class="card__head">SCS 8 NEW</div>
  </div>
  <div class="card">
    <img src={s8old}/>
    <div class="card__head">SCS 8 OLD</div>
  </div>
  <div class="card">
    <img src={s3}/>
    <div class="card__head">SCS 3</div>
  </div>
  
</div> 
<div>
{/* <Footer />  */}
</div>
    </section>
  )
}

export default Gallery