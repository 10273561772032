import React from 'react'
import s8Old from '../../../assets/productos/SCS-S8_OLD.png'
import Carousel from 'react-bootstrap/Carousel';
import S8Old_1 from '../../../assets/LifeStyleMobile/S8-OLD-1.webp'
import S8Old_2 from '../../../assets/LifeStyleMobile/S8-OLD-2.webp'
import S8Old_3 from '../../../assets/LifeStyleMobile/S8-OLD-3.webp'
import S8Old_4 from '../../../assets/LifeStyleMobile/S8-OLD-4.webp'
import features from '../../../../src/assets/productos/S8O-Features-Mobile.png'







export const S8OldScrollXMobile = () => {
  return (

    <div className='outer-wrapper mobile'>

      {/* SECCION 1 PART1  */}

      <div className='slide one direction'>
        <div className='features-container'>
          <div className='title-container-mobile'>
            <div className='title-featuress8'>
              <h2>S8 OLD</h2>
            </div>
            <div className='scs-features'>
              <img src={s8Old} />
            </div>
          </div>

        </div>
      </div>

      {/* SECCION 1 PARTE 2  */}

      <div className='slide one direction'>
        <div className='features-container'>
          <div className='features-img'>
            <img src={features} alt=""/>
          </div>
        </div>
      </div>

      {/* SECCION 2  */}

      <div className='slide two direction'>
        <div className='features-container'>
          <div className='descripcion-text'>
            <p className='d-text'>
              Proporciona una comunicación de manos libres muy
              efectiva mientras conduce al utilizar los comandos
              de voz se puede escuchar música estéreo de alta
              fidelidad, radio FM y guía de voz GPS.
            </p>
          </div>
        </div>
      </div>

      {/* SECCION 3 PARTE 1  */}
      <div className='slide two direction'>
        <div className='features-container'>
          <div className='life-title'>
            <h2>TRANSIMITE CADA EXPERIENCIA</h2>
          </div>
        </div>
      </div>

      {/* SECCION 3 PARTE 2  */}

      <div className='slide one direction'>
        <div className='features-container'>
          <div className='life-img-features'>
          <Carousel fade controls={false} interval={2000} indicators={false}>
              <Carousel.Item>
                <img
                  className="d-block"
                  src={S8Old_1}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block"
                  src={S8Old_2}
                  alt="Second slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block"
                  src={S8Old_3}
                  alt="Third slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block"
                  src={S8Old_4}
                  alt="Third slide"
                />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>

    </div>

  )
}

export default S8OldScrollXMobile