import React from 'react'
import s13 from '../../assets/productos/SCS-S13.png'
import xIconL from '../../assets/icons/x-iconL.png'
import S13ScrollX from './content/S13ScrollX '
import S13ScrollXMobile from './content-mobile/S13ScrollXMobile'




// import { IconName, AiOutlineClose } from "react-icons/ai";

export const S13XModal = () => {

  
  return (
    <div>
      
      <input type="checkbox" id="btn-modal7" />
      <label for="btn-modal7" className="lbl-modal7 pointer"><img src={s13} /></label>
      <h3 class="glassBox__title">S13</h3>
      <label for="btn-modal7" className="lbl-modal7 pointer"><h2>Saber más</h2></label>
      <div className="modal7">
        <div className="contenedor7">
          <label for="btn-modal7"><img src={xIconL} /></label>
          <div className='productos-content desktop'><S13ScrollX /></div>
          <div className='productos-content mobile'><S13ScrollXMobile /></div>
        </div>
      </div>
    </div>
  )
}

export default S13XModal