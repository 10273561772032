import React from 'react'
import LiveryImg from '../assets/img/LiveryMovil.png'

export const LiveryMovil = () => {
    return (
        <div className='livery_movil'>
            <img src={LiveryImg} />
        </div>
    )
}

export default LiveryMovil