import React from "react";
import s8New from '../../../assets/productos/SCS-S8_NEW.png'
import lifeStyle from '../../../assets/LifeStyle/SCS-S8N.png'
import features from '../../../../src/assets/productos/S8N-Features-Desktop.png'

export const S8NewScrollX = () => {
  return (
    <div className='outer-wrapper'>

      {/* SECCION 1  */}

      <div className='slide one direction'>
        <div className='features-container'>

          <div className='title-container'>
            <div className="div-title">
              <div className='title-featuress8'>
                <h2>S8 NEW</h2>
              </div>
              <div className='scs-features'>
                <img src={s8New} alt='S8 New SCS Intercomunicador Motocicleta'/>
              </div>
            </div>
            <div className='features-img'>
              <img src={features} alt='S8 New Intercom SCS motorcycle'/> 
          </div>
          </div>
          
        </div>
      </div>

      {/* SECCION 2  */}

      <div className='slide two direction'>
        <div className='features-container'>
          <div className='descripcion-text'>
            <p className='d-text'>
              Puede conectarse a cualquier reproductor de música
              con Bluetooth o dispositivo GPS, así como a su teléfono,
              un dispositivo totalmente integrado y compacto.
            </p>
          </div>
        </div>
      </div>

      {/* SECCION 3  */}

      <div className='slide two direction'>
        <div className='features-container'>
          <div className='life-title'>
            <div className="div-space">
              <h2>LA NUEVA<br/>FORMA DE<br/>CONECTAR</h2>
            </div>
            <div className='life-img-features'>
              <img src={lifeStyle} alt='S8 New Intercom Life Style motorcycle'/>
            </div>
          </div>

        </div>
      </div>

    </div>


  )
}

export default S8NewScrollX