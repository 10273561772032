import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import s11 from '../../../assets/productos/SCS-S11.png'
import features from '../../../../src/assets/productos/features-mobile.png'
import S11_1 from '../../../assets/LifeStyleMobile/S11-1.webp'
import S11_2 from '../../../assets/LifeStyleMobile/S11-2.webp'
import S11_3 from '../../../assets/LifeStyleMobile/S11-3.webp'
import S11_4 from '../../../assets/LifeStyleMobile/S11-4.webp'
import S11_5 from '../../../assets/LifeStyleMobile/S11-5.webp'
import S11_6 from '../../../assets/LifeStyleMobile/S11-6.webp'








export const S11ScrollXMobile = () => {
  return (

    <div className='outer-wrapper mobile'>

      {/* SECCION 1 PART1  */}

      <div className='slide one direction'>
        <div className='features-container'>
          <div className='title-container-mobile'>
            <div className='title-features'>
              <h2>S11</h2>
            </div>
            <div className='scs-features'>
              <img src={s11} />
            </div>
          </div>

        </div>
      </div>

      {/* SECCION 1 PARTE 2  */}

      <div className='slide one direction'>
        <div className='features-container'>
          <div className='features-img'>
            <img src={features} alt=""/>
          </div>
        </div>
      </div>

      {/* SECCION 2  */}

      <div className='slide two direction'>
        <div className='features-container'>
          <div className='descripcion-text'>
            <p className='d-text'>
              Diseñado para capturar cada momento en la carretera
              con una cámara de acción integrada. Bluetooth V5.0
              Distancia de funcionamiento: 800 mts Funcionamiento
              hasta 140 km/h
            </p>
          </div>
        </div>
      </div>

      {/* SECCION 3 PARTE 1  */}
      <div className='slide two direction'>
        <div className='features-container'>
          <div className='life-title'>
            <h2>GRABANDO LOS MEJORES MOMENTOS</h2>
          </div>
        </div>
      </div>

      {/* SECCION 3 PARTE 2  */}

      <div className='slide one direction'>
        <div className='features-container'>
        <div className='life-img-features'>
        <Carousel fade controls={false} interval={2000} indicators={false}>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={S11_1}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block h-100"
                  src={S11_2}
                  alt="Second slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block h-100"
                  src={S11_3}
                  alt="Third slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block h-100"
                  src={S11_4}
                  alt="Third slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block h-100"
                  src={S11_5}
                  alt="Third slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block h-100"
                  src={S11_6}
                  alt="Third slide"
                />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>

    </div>

  )
}

export default S11ScrollXMobile