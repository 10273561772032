import React from 'react'
import s8New from '../../assets/productos/SCS-S8_NEW.png'
import xIconL from '../../assets/icons/x-iconL.png'
import S8NewScrollX from './content/S8NewScrollX'
import S8NewScrollXMobile from './content-mobile/S8NewScrollXMobile'



// import { IconName, AiOutlineClose } from "react-icons/ai";

export const S8XNewModal = () => {
  return (
    <div>
      <input type="checkbox" id="btn-modal3" />
      <label for="btn-modal3" className="lbl-modal3 pointer"><img src={s8New} /></label>
      <h3 class="glassBox__title">S8 NEW</h3>
      <label for="btn-modal3" className="lbl-modal3 pointer"><h2>Saber más</h2></label>
      <div className="modal3">
        <div className="contenedor3">
          <label for="btn-modal3"><img src={xIconL} /></label>
          <div className='productos-content desktop'><S8NewScrollX /></div>
          <div className='productos-content mobile'><S8NewScrollXMobile /></div>
        </div>
      </div>
    </div>
  )
}

export default S8XNewModal