import React from "react";
import s13 from '../../../assets/productos/SCS-S13.png'
import lifeStyle from '../../../assets/LifeStyle/SCS-S13.png'
import features from '../../../../src/assets/productos/S13-Features-Desktop.png'






export const S13ScrollX = () => {



  return (
    <div className='outer-wrapper'>



      {/* SECCION 1  */}

      <div className='slide one direction'>
        <div className='features-container'>

          <div className='title-container'>
            <div className="div-title">
              <div className='title-features'>
                <h2>S13</h2>
              </div>
              <div className='scs-features'>
                <img src={s13} />
              </div>
            </div>
            <div className='features-img'>
              <img src={features}alt=""/> 
          </div>
          </div>
          
        </div>
      </div>

      {/* SECCION 2  */}

      <div className='slide two direction'>
        <div className='features-container'>
          <div className='descripcion-text'>
            <p className='d-text'>
              Diseñado para capturar cada momento en la carretera
              con una cámara de acción integrada. Bluetooth V5.0
              Distancia de funcionamiento: 500 mts Funcionamiento
              hasta 140 km/h
            </p>
          </div>
        </div>
      </div>

      {/* SECCION 3  */}
      <div className='slide two direction'>
        <div className='features-container'>
          <div className='life-title'>
            <div className="div-space-title text-gradiant">
              <h2>EL PODER<br/>DE ELEGIR<br/><span>TU ESTILO</span></h2>
            </div>
            <div className='life-img-features'>
              <img src={lifeStyle} />
            </div>
          </div>

        </div>
      </div>

    </div>


  )
}

export default S13ScrollX