import React from 'react'
import s8Old from '../../assets/productos/SCS-S8_OLD.png'
import xIconL from '../../assets/icons/x-iconL.png'
import S8OldScrollX from './content/S8OldScrollX.js'
import S8OldScrollXMobile from './content-mobile/S8OldXMobile'



// import { IconName, AiOutlineClose } from "react-icons/ai";

export const S8XOldModal = () => {
  return (
    <div>
      <input type="checkbox" id="btn-modal4" />
      <label for="btn-modal4" className="lbl-modal4 pointer"><img src={s8Old} /></label>
      <h3 class="glassBox__title">S8 OLD</h3>
      <label for="btn-modal4" className="lbl-modal4 pointer"><h2>Saber más</h2></label>
      <div className="modal4">
        <div className="contenedor4">
          <label for="btn-modal4"><img src={xIconL} /></label>
          <div className='productos-content desktop'><S8OldScrollX /></div>
          <div className='productos-content mobile'><S8OldScrollXMobile /></div>
        </div>
      </div>
    </div>
  )
}

export default S8XOldModal