import React from 'react'
import s7Evo from '../../../assets/productos/SCS-S7_EVO.png'
import features from '../../../../src/assets/productos/S7-Features-Mobile.png'
import Carousel from 'react-bootstrap/Carousel'
import S7Evo_1 from '../../../assets/LifeStyleMobile/S7-EVO-1.webp'
import S7Evo_2 from '../../../assets/LifeStyleMobile/S7-EVO-2.webp'
import S7Evo_3 from '../../../assets/LifeStyleMobile/S7-EVO-3.webp'
import S7Evo_4 from '../../../assets/LifeStyleMobile/S7-EVO-4.webp'






export const S7EvoScrollXMobile = () => {
  return (

    <div className='outer-wrapper mobile'>

      {/* SECCION 1 PART1 7Evo*/}

      <div className='slide one direction'>
        <div className='features-container'>
          <div className='title-container-mobile'>
            <div className='title-featuress8'>
              <h2>S7 Evo</h2>
            </div>
            <div className='scs-features'>
              <img src={s7Evo} />
            </div>
          </div>

        </div>
      </div>

      {/* SECCION 1 PARTE 2  */}

      <div className='slide one direction'>
        <div className='features-container'>
          <div className='features-img'>
            <img src={features} alt=""/>
          </div>
        </div>
      </div>

      {/* SECCION 2  */}

      <div className='slide two direction'>
        <div className='features-container'>
          <div className='descripcion-text'>
            <p className='d-text'>
              Escucha música de forma inalámbrica con tecnología
              Bluetooth integrada. Puede realizar o recibir llamadas
              a través de Bluetooth. Batería de iones de litio.
            </p>
          </div>
        </div>
      </div>

      {/* SECCION 3 PARTE 1  */}
      <div className='slide two direction'>
        <div className='features-container'>
          <div className='life-title'>
            <h2>CONECTADOS DURANTE LA AVENTURA</h2>
          </div>
        </div>
      </div>

      {/* SECCION 3 PARTE 2  */}

      <div className='slide one direction'>
        <div className='features-container'>
          <div className='life-img-features'>
          <Carousel fade controls={false} interval={2000} indicators={false}>
              <Carousel.Item>
                <img
                  className="d-block"
                  src={S7Evo_1}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block"
                  src={S7Evo_2}
                  alt="Second slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block"
                  src={S7Evo_3}
                  alt="Third slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block"
                  src={S7Evo_4}
                  alt="Third slide"
                />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>

    </div>

  )
}

export default S7EvoScrollXMobile