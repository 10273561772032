import React from 'react'
import lifestyle from '../assets/img/scs11lifestyle.jpg'
import lifestyles11hover from '../assets/img/s11_div.jpg'
import s11 from '../assets/img/microslider_S11_1.jpg'
import s11hover from '../assets/img/microslider_S11.jpg'
import s8new from '../assets/img/S8_div_s-letreros.jpg'
import s8newhover from '../assets/img/S8_div_c-letreros.jpg'
import s7evo from '../assets/img/s7.jpg'
import s7evohover from '../assets/img/s7_con-letreros.jpg'
import lifestyles7hover from '../assets/img/s7-div.jpg'
import Novedades from "../components/Novedades"
import s13 from '../assets/img/S13_div_s-letreros.jpg'
import s13hover from '../assets/img/S13_div_c-letreros.jpg'
import Carousel from 'react-bootstrap/Carousel';
import s13g from '../../src/assets/img/s13gray.png'
import s13b from '../../src/assets/img/s13blue.png'
import s13bl from '../../src/assets/img/s13black.png'




export const Destacados = () => {
  return (
    <section id='destacados'>
      <Novedades />
      <div className='destacados_content'>
        <div className='destacados_box'>

          {/* SECCION DEL S13 */}

          <div className='s8new'>
          <label for="btn-modal7" className="lbl-modal7 pointer">
            <div className='s8newbox' data-aos="fade-up" data-aos-duration="1000">
            
              <div className='s13-intercomunicador'>
                <Carousel fade indicators={false} interval={2000} pause={false}>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={s13b}
                      alt="First slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={s13g}
                      alt="Second slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={s13bl}
                      alt="Second slide"
                    />
                  </Carousel.Item>
                </Carousel>
              </div>
              
              <div className='s8newposition'>
                <h3>Elige tu propio estilo</h3>
                <div className='s8newimg'>
                  <img src={s13} alt="S8 New Intercomunicador SCS" />
                </div>
                <div className='s8newimghover'>
                  <img src={s13hover} alt="S8 New Intercomunicador SCS" />
                </div>
              </div>

            </div>
            </label>
          </div>

          {/* SECCION DEL S11 */}
          <div className='s11' data-aos="fade-up" data-aos-duration="1000">
          
            <div className='s11box2'>
              <div className='s11boxposition2'>
                <h2>Lo mejor de los dos mundos.</h2>
                <div className='s11img2'>

                  <img src={lifestyle} />
                </div>
                <div className='s11img2hover'>
                  <img src={lifestyles11hover} />
                </div>
              </div>
            </div>
            <label for="btn-modal1" className="lbl-modal1 pointer">
            <div className='s11box1'>
              <div className='s11boxposition'>
                <div className='s11img'>
                  <img src={s11} alt="s11 Intercomunicador SCS" />
                </div>
                <div className='s11imghover'>
                  <img src={s11hover} alt="s11 Intercomunicador SCS" />
                </div>
              </div>
            </div>
            </label>
          
          </div>

          {/* SECCION DEL S8  */}
          <div className='s8new'>
          <label for="btn-modal3" className="lbl-modal3 pointer">
            <div className='s8newbox' data-aos="fade-up" data-aos-duration="1000">
              <div className='s8newposition'>
                <h2>Exprésate mientras llueve.</h2>
                <div className='s8newimg'>
                  <img src={s8new} alt="S8 New Intercomunicador SCS" />
                </div>
                <div className='s8newimghover'>
                  <img src={s8newhover} alt="S8 New Intercomunicador SCS" />
                </div>
              </div>

            </div>
            </label>
          </div>


          {/* S7EVO SECCIÓN */}
          
          <div className='s7evo' data-aos="fade-up" data-aos-duration="1000">
          <label for="btn-modal5" className="lbl-modal5 pointer">
            <div className='s7evobox1'>
              <div className='s7evoposition'>
                <div className='s7evoimg'>
                  <img src={s7evo} alt="S7Evo Intercomunicador SCS" />
                </div>
                <div className='s7evoimghover'>
                  <img src={s7evohover} alt="S7Evo Intercomunicador SCS" />
                </div>
              </div>

            </div>
            </label>
            <div className='s7evobox2'>
              <div className='s7evoposition2'>
                <h2>Elegante, versátil y de fácil instalación.</h2>
                <div className='s7evoimg2'>
                  <div className='s7evoimg2'>
                    <img src={lifestyle} />
                  </div>
                  <div className='s7evoimg2hover'>
                    <img src={lifestyles7hover} alt="S7Evo Intercomunicador SCS" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Destacados
