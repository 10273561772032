import React from "react";
import s9 from '../../../assets/productos/SCS-S9.png'
import lifeStyle from '../../../assets/LifeStyle/SCS-S9.png'
import features from '../../../../src/assets/productos/S9-Features-Desktop.png'






export const S9ScrollX = () => {



  return (
    <div className='outer-wrapper'>



      {/* SECCION 1  */}

      <div className='slide one direction'>
        <div className='features-container'>

          <div className='title-container'>
            <div className="div-title">
              <div className='title-features'>
                <h2>S9</h2>
              </div>
              <div className='scs-features'>
                <img src={s9} alt='S9 SCS Intercomunicador motos'/>
              </div>
            </div>
            <div className='features-img'>
              <img src={features} alt=""/> 
          </div>
          </div>
          
        </div>
      </div>

      {/* SECCION 2  */}

      <div className='slide two direction'>
        <div className='features-container'>
          <div className='descripcion-text'>
            <p className='d-text'>
              Proporciona una comunicación de manos libres muy
              efectiva mientras conduce al utilizar los comandos de
              voz Siri (iPhone) y S-Voice (Android), con la que puede
              realizar o recibir llamadas a través de Bluetooth, etc.
            </p>
          </div>
        </div>
      </div>

      {/* SECCION 3  */}
      <div className='slide two direction'>
        <div className='features-container'>
          <div className='life-title'>
            <div className="div-space">
              <h2>LA VELOCIDAD<br/>Y LA<br/>COMUNICACIÓN<br/>JUNTAS</h2>
            </div>
            <div className='life-img-features'>
              <img src={lifeStyle} />
            </div>
          </div>

        </div>
      </div>

    </div>


  )
}

export default S9ScrollX