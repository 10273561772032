import React from "react";
import s11 from '../../../assets/productos/SCS-S11.png'
import lifeStyle from '../../../assets/LifeStyle/SCS-S11.png'
import features from '../../../../src/assets/productos/features-desktop.png'






export const S11ScrollX = () => {

  

  return (
    <div className='outer-wrapper'>

    

      {/* SECCION 1  */}

      <div className='slide one direction'>
        <div className='features-container'>

          <div className='title-container'>
            <div className="div-title">
              <div className='title-features'>
                <h2>S11</h2>
              </div>
              <div className='scs-features'>
                <img src={s11} />
              </div>
            </div>
            <div className='features-img s13i'>
              <img src={features} alt=''/> 
          </div>
          </div>
          
        </div>
      </div>
      {/* SECCION 2  */}

      <div className='slide two direction'>
        <div className='features-container'>
          <div className='descripcion-text'>
            <p className='d-text'>
              Diseñado para capturar cada momento en la carretera
              con una cámara de acción integrada. Bluetooth V5.0
              Distancia de funcionamiento: 800 mts Funcionamiento
              hasta 140 km/h
            </p>
          </div>
        </div>
      </div>

      {/* SECCION 3  */}
      <div className='slide two direction'>
        <div className='features-container'>
          <div className='life-title'>
            <div className="div-space">
              <h2>GRABANDO <br></br>LOS MEJORES<br></br> MOMENTOS</h2>
            </div>
            <div className='life-img-features'>
              <img src={lifeStyle} />
            </div>
          </div>

        </div>
      </div>

    </div>


  )
}

export default S11ScrollX