import React from "react";
import s8Old from '../../../assets/productos/SCS-S8_OLD.png'
import lifeStyle from '../../../assets/LifeStyle/SCS-S8O.png'
import features from '../../../../src/assets/productos/S8O-Features-Desktop.png'

export const S8OldScrollX = () => {
  return (
    <div className='outer-wrapper'>

      {/* SECCION 1  */}

      <div className='slide one direction'>
        <div className='features-container'>

          <div className='title-container'>
            <div className="div-title">
              <div className='title-featuress8'>
                <h2>S8 OLD</h2>
              </div>
              <div className='scs-features'>
                <img src={s8Old} alt='S8 Old SCS Intercomunicador Motocicleta'/>
              </div>
            </div>
            <div className='features-img s13i'>
              <img src={features} alt='SCS S8 Old Intercom motorcycle'/> 
          </div>
          </div>
          
        </div>
      </div>

      {/* SECCION 2  */}

      <div className='slide two direction'>
        <div className='features-container'>
          <div className='descripcion-text'>
            <p className='d-text'>
              Proporciona una comunicación de manos libres muy
              efectiva mientras conduce al utilizar los comandos
              de voz se puede escuchar música estéreo de alta
              fidelidad, radio FM y guía de voz GPS.
            </p>
          </div>
        </div>
      </div>

      {/* SECCION 3  */}

      <div className='slide two direction'>
        <div className='features-container'>
          <div className='life-title'>
            <div className="div-space">
              <h2>TRANSIMITE<br/>CADA EXPERIENCIA</h2>
            </div>
            <div className='life-img-features'>
              <img src={lifeStyle} alt='S8 Old Intercom Life Style motorcycle'/>
            </div>
          </div>

        </div>
      </div>

    </div>


  )
}

export default S8OldScrollX