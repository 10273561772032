import React from 'react'
import distriLogo from '../../src/assets/img/distriLogo.png'
import { IconName, FaWhatsapp} from "react-icons/fa";
import S11XModal from './info-items/S11XModal';
import S9XModal from './info-items/S9XModal';

export const Footer = () => {
  return (
    <div>
      <footer class="footer-distributed">
   

        {/* <div class="footer-right">

          <a href="#"><FaWhatsapp /></a>
          

        </div> */}

        <div class="distri-logo">

          <div className='distri-img'>
            <a href='https://distrifabrica.com/' target="_blank"><img src={distriLogo} alt="DistriFabricas Ramirez S.A"/></a>
          </div>

          <p>Distrifabrica Ramirez S.A.S, &copy; todos los derechos reservados 2022</p>
        </div>

        <div>
          
        </div>

      </footer>
    </div>
  )
}

export default Footer