import React from 'react'
import Carousel from '../components/Carousel'
import CarouselMobile from './CarouselMobile'

export const Products = () => {
  return (
    <section className='products' id='products'>
        <div className='productcsContent'>
            <Carousel/>
            <CarouselMobile />
        </div>
    </section>
  )
}
