import { useEffect, useState } from "react";
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";
import { Animator, ScrollContainer, ScrollPage } from 'react-scroll-motion'
import "./sass/main.scss";
import { CDBFooter } from 'cdbreact';
// Components
import Header from "./components/Header";
import Banner from "./components/Banner";
import Loader from "./components/Loader";
import { Products } from "./components/Products";
import { Footer } from "./components/Footer";
import NavbarMobile from "./components/NavbarMobile";
import Gallery from "./components/Gallery";
import Destacados from "./components/Destacados";
import DestacadosMobile from "./components/DestacadosMobile";
import Distribuidores from "./components/Distribuidores";
import Livery from "./components/Livery";
import LiveryMovil from "./components/LiveryMovil";






function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loading
      ? document.querySelector("body").classList.add("loading")
      : document.querySelector("body").classList.remove("loading");
  }, [loading]);

  return (
    <ScrollContainer>
      <AnimateSharedLayout type='crossfade'>
        <AnimatePresence>
          {loading ? (
            <motion.div key='loader'>
              <Loader setLoading={setLoading} />
            </motion.div>
          ) : (
            <>
              <NavbarMobile />
              <Header />
              <Banner />
              {!loading && (
                <div className='transition-image final'>
                  <motion.img
                    transition={{ ease: [0.6, 0.01, -0.05, 0.9], duration: 1.6 }}
                    src={process.env.PUBLIC_URL + `/images/image-2.webp`}
                    layoutId='main-image-1'
                  />
                </div>
              )}
              
              <Products />
              <Destacados />
              <DestacadosMobile />
              <Gallery />
              <Distribuidores />
              
              <Livery />
              <LiveryMovil />
              {/* <ScrollX /> */}
              <Footer />
            </>
          )}
        </AnimatePresence>
      </AnimateSharedLayout>
    </ScrollContainer>
   
  );
}

export default App;
