import React from 'react'
import s3 from '../../assets/productos/SCS-S3.png'
import xIconL from '../../assets/icons/x-iconL.png'
import S3ScrollX from './content/S3ScrollX'
import  S3ScrollXMobile  from './content-mobile/S3XMobile'



// import { IconName, AiOutlineClose } from "react-icons/ai";

export const S3XModal = () => {

  
  return (
    <div>
      
      <input type="checkbox" id="btn-modal6" />
      <label for="btn-modal6" className="lbl-modal6 pointer"><img src={s3} /></label>
      <h3 class="glassBox__title">S3</h3>
      <label for="btn-modal6" className="lbl-modal6 pointer"><h2>Saber más</h2></label>
      <div className="modal6">
        <div className="contenedor6">
          <label for="btn-modal6"><img src={xIconL} /></label>
          <div className='productos-content desktop'><S3ScrollX /></div>
          <div className='productos-content mobile'><S3ScrollXMobile /></div>
        </div>
      </div>
    </div>
  )
}

export default S3XModal