import React from 'react'
import box from '../assets/img/importado.png'

export const Livery = () => {
  return (
    <div className='livery'>
        <img src={box}/>
    </div>
  )
}

export default Livery