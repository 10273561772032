import s3 from '../../../assets/productos/SCS-S3.png'
import lifeStyle from '../../../assets/LifeStyle/SCS-S3.png'
import features from '../../../../src/assets/productos/S3-Features-Desktop.png'





export const S3ScrollX = () => {


  return (


    <div className='outer-wrapper'>

      <div>


        {/* SECCION 1  */}

        <div className='slide one direction'>
        <div className='features-container'>

          <div className='title-container'>
            <div className="div-title">
              <div className='title-features'>
                <h2>S3</h2>
              </div>
              <div className='scs-features'>
                <img src={s3} alt='Intercomunicador SCS S3' />
              </div>
            </div>
            <div className='features-img s13i'>
              <img src={features} alt="Intercomunicador SCS S3"/> 
          </div>
          </div>
          
        </div>
      </div>

        {/* SECCION 2  */}

        <div className='slide two direction'>
          <div className='features-container'>
            <div className='descripcion-text'>
              <p className='d-text'>
                Proporciona covertura a los conductores separados
                por 1000 mts. Audio HD y reducción de ruido avanzada,
                Estructura a prueba de lluvia, función de mensajes de
                voz, etc.
              </p>
            </div>
          </div>
        </div>

        {/* SECCION 3  */}

        <div className='slide two direction'>
        <div className='features-container'>
          <div className='life-title'>
            <div className="div-space">
              <h2>CONECTA<br/>CON TODOS</h2>
            </div>
            <div className='life-img-features'>
              <img src={lifeStyle} alt='Intercomunicador S13 LifeStyle'/>
            </div>
          </div>

        </div>
      </div>
    </div>
    </div>


  )
}

export default S3ScrollX