import React from 'react'
import s7Evo from '../../assets/productos/SCS-S7_EVO.png'
import xIconL from '../../assets/icons/x-iconL.png'
import S7EvoScrollX from './content/S7EvoScrollX'
import S7EvoScrollXMobile from './content-mobile/S7XMobile'



// import { IconName, AiOutlineClose } from "react-icons/ai";

export const S7EvoXModal = () => {
  return (
    <div>
      <input type="checkbox" id="btn-modal5" />
      <label for="btn-modal5" className="lbl-modal5 pointer"><img src={s7Evo} /></label>
      <h3 class="glassBox__title">S7 EVO</h3>
      <label for="btn-modal5" className="lbl-modal5 pointer"><h2>Saber más</h2></label>
      <div className="modal5">
        <div className="contenedor5">
          <label for="btn-modal5"><img src={xIconL} /></label>
          <div className='productos-content desktop'><S7EvoScrollX /></div>
          <div className='productos-content mobile'><S7EvoScrollXMobile /></div>
        </div>
      </div>
    </div>
  )
}

export default S7EvoXModal